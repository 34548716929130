import { Injectable } from '@angular/core';
import { UserRole } from '../models/auth.models';
import { TachographStatus } from '../models/registration.models';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(protected authSrv: AuthService) {}

  getMenu() {
    const user = this.authSrv.currentUser;

    if (!user) {
      return [];
    }

    switch (user.role) {
      case UserRole.SUPERADMIN: {
        return [
          {
            title: 'Início',
            icon: 'home',
            link: '/home',
          },
          {
            title: 'Administradores',
            icon: 'team',
            link: '/admins',
          },
          {
            title: 'Utilizadores',
            icon: 'team',
            link: '/users',
          },
          {
            title: 'Empresas',
            icon: 'build',
            link: '/companies',
          },
          {
            title: 'Veículos',
            icon: 'car',
            link: '/vehicles',
          },
          {
            title: 'RITT',
            icon: 'dashboard',

            children: [
              {
                title: 'Registos',
                icon: 'ordered-list',
                link: '/registrations',
              },
              {
                title: 'Registos Inválidos',
                icon: 'unordered-list',
                link: '/error-registrations',
              },
              {
                title: 'Licenças',
                icon: 'file-done',
                link: '/licenses',
              },
            ],
          },
          {
            title: 'Guias Transporte',
            icon: 'book',
            children: [
              {
                title: 'Guias',
                icon: 'ordered-list',
                link: '/guides',
              },
              {
                title: 'Relatórios',
                icon: 'file-done',
                link: '/guides-reports',
              },
              {
                title: 'Entidades',
                icon: 'user',
                link: '/guides-entities',
              },
            ],
          },
          {
            title: 'Erros',
            icon: 'bug',
            link: '/errors',
          },
          {
            title: 'Logs',
            icon: 'solution',
            link: '/logs',
          },
          {
            title: 'Configurações',
            icon: 'setting',
            link: '/configurations',
          },
          {
            title: 'Configurações Sistema',
            icon: 'control',
            link: '/system-configurations',
          },
          {
            title: 'Ajuda',
            icon: 'info-circle',
            link: '/help',
          },
        ];
      }
      case UserRole.ANTRAM: {
        return [
          {
            title: 'Início',
            icon: 'home',
            link: '/home',
          },
          {
            title: 'Administradores',
            icon: 'team',
            link: '/admins',
          },
          {
            title: 'Empresas',
            icon: 'build',
            link: '/companies',
          },
          {
            title: 'Utilizadores',
            icon: 'team',
            link: '/users',
          },
          {
            title: 'Faturas',
            icon: 'file-done',
            link: 'invoices',
          },
          {
            title: 'Configurações',
            icon: 'setting',
            link: '/configurations',
          },
          {
            title: 'Configurações Sistema',
            icon: 'control',
            link: '/system-configurations',
          },
          {
            title: 'Ajuda',
            icon: 'info-circle',
            link: '/help',
          },
        ];
      }
      case UserRole.ADMIN: {
        return [
          {
            title: 'Início',
            icon: 'home',
            link: '/home',
          },
          {
            title: 'Administradores',
            icon: 'team',
            link: '/admins',
          },
          {
            title: 'Colaboradores',
            icon: 'team',
            link: '/users',
          },
          {
            title: 'Veículos',
            icon: 'car',
            link: '/vehicles',
          },
          {
            title: 'Faturas',
            icon: 'file-done',
            link: '/invoices',
          },
          {
            title: 'RITT',
            icon: 'dashboard',
            hidden: !user.company?.hasRitt,
            children: [
              {
                title: 'Registos',
                icon: 'ordered-list',
                link: '/registrations',
              },
              {
                title: 'Licenças',
                icon: 'file-done',
                link: '/licenses',
              },
            ],
          },
          {
            title: 'Guias Transporte',
            icon: 'book',
            hidden: !user.company?.hasGuias,
            children: [
              {
                title: 'Guias',
                icon: 'ordered-list',
                link: '/guides',
              },
              {
                title: 'Relatórios',
                icon: 'file-done',
                link: '/guides-reports',
              },
              {
                title: 'Entidades',
                icon: 'user',
                link: '/guides-entities',
              },
            ],
          },
          {
            title: 'Ajuda',
            icon: 'info-circle',
            link: '/help',
          },
        ];
      }
      default: {
        return [
          {
            title: 'Início',
            icon: 'home',
            link: '/home',
          },
          {
            title: 'Colaboradores',
            icon: 'team',
            link: '/users',
          },
          {
            title: 'Veículos',
            icon: 'car',
            link: '/vehicles',
          },
          {
            title: 'Faturas',
            icon: 'file-done',
            link: '/invoices',
          },
          {
            title: 'RITT',
            icon: 'dashboard',
            hidden: !user.company?.hasRitt,
            children: [
              {
                title: 'Registos',
                icon: 'ordered-list',
                link: '/registrations',
              },
              {
                title: 'Licenças',
                icon: 'file-done',
                link: '/licenses',
              },
            ],
          },
          {
            title: 'Guias Transporte',
            icon: 'book',
            hidden: !user.company?.hasGuias,
            children: [
              {
                title: 'Guias',
                icon: 'ordered-list',
                link: '/guides',
              },
              {
                title: 'Relatórios',
                icon: 'file-done',
                link: '/guides-reports',
              },
            ],
          },
          {
            title: 'Ajuda',
            icon: 'info-circle',
            link: '/help',
          },
        ];
      }
    }
  }

  getRegistrationStatus() {
    return [
      // { value: TachographStatus.AUSENCIA, text: 'Ausência' },
      { value: TachographStatus.CONDUCAO, text: 'Condução' },
      { value: TachographStatus.DESCANSO, text: 'Descanso' },
      { value: TachographStatus.DISPONIBILIDADE, text: 'Disponibilidade' },
      // { value: TachographStatus.DISPONIBILIDADE_2, text: 'Disponibilidade (Condução em Pares)' },
      { value: TachographStatus.OUTRO_EMPREGADOR, text: 'Outro Empregador' },
      { value: TachographStatus.TRABALHO, text: 'Outros Trabalhos' },
      // { value: TachographStatus.TROCA_VEICULO, text: 'Troca de Veículo' },
      { value: TachographStatus.INICIO, text: 'Início de Serviço' },
      { value: TachographStatus.FIM, text: 'Fim de Serviço' },
      { value: TachographStatus.NR, text: 'Não Registado' },
    ];
  }

  getAdminRoles() {
    let roles: any[] = [];
    if (this.authSrv.currentUser?.isSuperAdmin) {
      roles = [
        { value: UserRole.SUPERADMIN, text: 'Administrador do Sistema' },
        { value: UserRole.ANTRAM, text: 'Antram' },
        { value: UserRole.ADMIN, text: 'Administrador de Empresa' },
      ];
    } else if (this.authSrv.currentUser?.isAntram) {
      roles = [
        { value: UserRole.ANTRAM, text: 'Antram' },
        { value: UserRole.ADMIN, text: 'Administrador de Empresa' },
      ];
    } else {
      roles = [{ value: UserRole.ADMIN, text: 'Administrador de Empresa' }];
    }
    return roles;
  }

  getColorScheme() {
    return [
      '#f5222d',
      '#fa541c',
      '#fa8c16',
      '#faad14',
      '#fadb14',
      '#a0d911',
      '#52c41a',
      '#13c2c2',
      '#1677ff',
      '#2f54eb',
      '#722ed1',
      '#eb2f96',
      '#bfbfbf',
    ];
  }
}
