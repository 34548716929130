import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/service/auth.service';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { LoginType, User } from '../core/models/auth.models';
import { MenuItem } from '../core/models/menu';
import { DataService } from '../core/service/data.service';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.less'],
})
export class LayoutContainerComponent implements OnInit {
  currentUser: User | null = null;

  menu: MenuItem[] = [];
  isCollapsed = false;

  version: string = environment.version;
  year: number = 2023;
  currentYear: number = moment().year();

  LoginType = LoginType;

  get yearInfo() {
    return this.currentYear === this.year ? this.currentYear : `${this.year} - ${this.currentYear}`;
  }

  constructor(
    protected authSrv: AuthService,
    protected router: Router,
    protected dataSrv: DataService,
    private titleSrv: Title
  ) {
    this.currentUser = this.authSrv.currentUser;

    if (this.authSrv.guideToken) {
      // this.titleSrv.setTitle(`eGuias - Guia de Transporte Eletrónica`);
    }
  }

  ngOnInit() {
    this.menu = this.dataSrv.getMenu();
  }

  profile() {
    this.router.navigate(['/profile']);
  }

  logout() {
    this.authSrv
      .logout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/auth/login']);
      });
  }

  get isGuest() {
    return this.authSrv.guideToken;
  }
}
