import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { AuthService } from '../service/auth.service';
import { filter, first, switchMap, takeWhile } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUser;

    if (request.headers.has('no-token') || request.url.includes('.json')) {
      return next.handle(request);
    }

    if (this.authService.guideToken) {
      let companyId = this.authService.guideToken.companyId;
      if (companyId && !request.headers.has('company-id')) {
        request = request.clone({
          headers: request.headers.append('company-id', companyId),
        });
      }
    }

    if (!currentUser || !currentUser.token) {
      return next.handle(request);
    }

    const token = currentUser.token;
    if (!token) {
      return next.handle(request);
    }

    const expiresIn = currentUser.expiresIn;
    if (expiresIn && moment().isAfter(moment(expiresIn).subtract(15, 'minutes')) && !this.authService.isRefreshing) {
      return this.authService.refreshToken().pipe(
        first(),
        switchMap(() => {
          const cloned = request.clone({
            headers: request.headers.append('Authorization', `Bearer ${token}`),
          });
          return next.handle(cloned);
        })
      );
    } else {
      if (!this.authService.isRefreshing) {
        const cloned = request.clone({
          headers: request.headers.append('Authorization', `Bearer ${token}`),
        });
        return next.handle(cloned);
      }

      return this.authService.$isRefreshing.pipe(
        takeWhile((r) => r, true),
        filter((v) => v === false),
        switchMap(() => {
          const cloned = request.clone({
            headers: request.headers.append('Authorization', `Bearer ${token}`),
          });
          return next.handle(cloned);
        })
      );
    }
  }
}
