import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authSrv: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          const ignore401 = request.headers.has('ignore401');
          if (!ignore401) {
            this.authSrv.logout();
          }
        }

        let error =
          err.error.errors && err.error.errors.length > 0
            ? err.error.errors[0].error
            : err.error.error
            ? err.error.error
            : err.statusText || err.statusText;

        return throwError(error);
      })
    );
  }
}
